<template>
  <div class="training-options-page">
    <div class="main">
      <ul class="list">
        <li
          v-for="item in list"
          :key="item.title"
          class="list-item"
        >
          <div class="intro-img">
            <img :src="item.imgUrl" />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="bottom">
            <div class="left">
              <i
                class="icon"
                :class="{
                  'in-progress': item.status == 1,
                  closed: item.status != 1,
                }"
              />
              <span class="status-text">
                {{
                  item.status == 1 ? '进行中' : item.status == 2 ? '已结束' : '待开放'
                }}
              </span>
            </div>
              <!--          @click="onTrainClick(item)"-->
              <div>
              <van-button  v-if="item.status == 1" class="right-btn"  @click="onRegisterClick(item)">
                  {{item.routePath === '/slfh'? '单位注册':'企业注册'}}
              </van-button>
              <van-button  v-if="item.status == 1" class="right-btn"  @click="onTrainClick(item)">
              进入培训
            </van-button>
              </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const dev_options_session_key = 'devOptions';

export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getOptionsList();
  },
  methods: {
    getOptionsList() {
      const params = {
        dataId: '63522dde12598315116f09e2',
      };

      this.$api.yj_training_options.getOptionsList(params).then((res) => {
        const { list } = res.data.dataDto.data;

        this.list = list;
      });
    },

   //企业注册
    onRegisterClick(item) {
          // 已结束的则不进行跳转
          if (item.status == 2){
              // this.$toast("培训已结束");
              this.$message.info('培训已结束')
              return;
          }

        this.$router.push(item.BRegistration);


    },

    // 进入各个项目的首页
    onTrainClick(item) {
      // 已结束的则不进行跳转
      if (item.status == 2){
          // this.$toast("培训已结束");
          this.$message.info('培训已结束')
          return;
      }
      const devOptions = localStorage.getItem(dev_options_session_key);
      // 用户选择的培训项目是否已存储
      if (devOptions && devOptions.length) {
        const firstOption = JSON.parse(devOptions)[0];
        // 用户已选择的培训项目是否和当前选择的一样
        const isSame = item.routePath === `/${firstOption.sign}`;
        if (!isSame && localStorage.getItem('token')) {
          this.$store.commit('logout');
        }
      }


      this.$router.push(item.routePath);
    },
  },
};
</script>

<style lang="less" scoped>
.training-options-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8fafc;
}

.main {
  padding: 40px 30px 30px;
}
.list{
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.list-item {
  margin-bottom: 30px;
  width: 580px;
  height: 315px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  .intro-img {
    height: 200px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .title {
    padding: 20px 0 20px 17px;
    line-height: 23px;
    letter-spacing: 1px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;



  }

  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .left {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 20px;
      height: 24px;

      &.in-progress {
        background: url(../assets/imgs/in_progress.png) no-repeat;
        background-size: contain;
      }

      &.closed {
        background: url(../assets/imgs/closed.png) no-repeat;
        background-size: contain;
      }
    }

    .status-text {
      padding-left: 8px;
      width: 72px;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9da1ae;
      line-height: 33px;
    }

    .right-btn {
      padding: 0;
      width: 120px;
      margin-right: 16px;
      height: 40px;
      background: #316FFF;
      border-radius: 20px;
      border: none;
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
    }
  }
}
</style>
